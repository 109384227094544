
body {
  margin: 0;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #444444;
}

a {
  text-decoration: none;
  color: #002e33;
}

a:hover {
  color: #005961;
  cursor: pointer;
}
